<template>
  <div class="space-y-4">
    <div class="space-y-4">
      <article v-if="canCreateCashDeclarationForm">
        <h3 class="subheader">
          Quick Links
        </h3>
        <br />
        <section class="relative z-0 rounded-md grid md:grid-cols-4">
          <asom-button variant="primary" outline rounded="none"
            @click="$router.push('/cash-mgnt/end-discrepancy-form/create')" icon="duplicate"
            text="Create Manual Change Month End Declaration">
          </asom-button>
        </section>
      </article>

      <div v-if="!isLoading" class="space-y-4">
        <!-- filter begin-->
        <asom-card title="Filter">
          <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
            <asom-form-field label="Date" required :state="inputStates('filters.dateRange')"
              error="Please select a valid date range">
              <asom-input-date-range v-model="filters.dateRange" datepicker-placement="bottom"
                :state="inputStates('filters.dateRange')" />
            </asom-form-field>
            <asom-form-field label="Line" required :state="inputStates('filters.line')"
              error="Please select a MRT line">
              <asom-input-select
                :disabled="!canSelectLine"
                v-model="filters.line"
                :options="lineOptions"
              />
            </asom-form-field>
            <asom-form-field label="Station" required :state="inputStates('filters.station')"
              error="Please select a MRT station">
              <asom-input-select v-model="filters.station" :options="stationOptions"
                :state="inputStates('filters.station')" />
            </asom-form-field>
          </div>

          <template #footer>
            <asom-button text="Reset Filters" variant="secondary" @click="resetFilters" />
            <asom-button text="Apply" :loading="isSubmitting" @click="getPageData" />
          </template>
        </asom-card>
        <!-- filter end-->
        <asom-card title="Month End Declaration Form Log">
          <asom-client-table v-if="!isLoading" :data="tableData"
            :columns="[
        'referenceNo',
        'createdBy',
        'dateCreated',
        'time'
      ]" :sortableColumns="[
        'referenceNo',
        'dateCreated',
        'createdBy',
        'time',
      ]"
       :searchableDateColumns="['dateCreated']"
       :searchableCurrencyColumns="['amount']">
            <template v-slot:header_referenceNo>Reference No</template>
            <template v-slot:header_createdBy>Reported by</template>
            <template v-slot:header_dateCreated>Date</template>
            <template v-slot:header_time>Time</template>

            <template v-slot:referenceNo="scopedSlots">
              <div class="flex flex-row">
                <asom-link @click="navigateToView(scopedSlots.rowData)">
                  <span :class="scopedSlots.rowData.isDeleted && 'line-through'">{{ scopedSlots.data }}</span>
                </asom-link>
              </div>
            </template>

            <template v-slot:createdBy="scopedSlots">{{ scopedSlots.data }}</template>
            <template v-slot:dateCreated="scopedSlots">
              <span>{{ displayUtcDate(scopedSlots.data) }}</span>
            </template>
            <template v-slot:time="scopedSlots">
              <span>{{ displayUtcTime(scopedSlots.rowData.dateCreated) }}</span>
            </template>
          </asom-client-table>
        </asom-card>
      </div>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash.get";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { displayUtcDate, displayUtcTime } from "@/helpers/dateTimeHelpers";
import { getMonthendDeclarAtions} from "@/services/cashManagement.service";
import { formatCurrency } from "../../../helpers/numbers";
import { cdf } from "../../../constants/APIEnums/cashManagementEnums";
import moment from "moment";
import { toMoment } from "@/helpers/dateTimeHelpers";

export default {
  name: "CashDeclarationList",
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      filters: {
        dateRange: {
          start: moment().subtract(1, "months").toDate(),
          end: moment().toDate(),
        },
        station: null,
        line: null,
      },
      isSubmitting: false,
      cdfFormList: [],
      tableData: [],
      isLoading: true,
      formStatus: get(cdf, "cdfStatus", ""),
      selectedCDF: null,
      showDeleteModal: false,
      isSubmittingDelete: false,
    };
  },
  watch: {
    "filters.line": function (newValue) {
      if (newValue !== this.filters.line) { // 线路发生改变时重置站点选择
        this.filters.station = null;
        this.getPageData();
      }
    },
    "filters.station": function () {
      this.isSubmitting = true;
      this.getPageData();
    },
  },
  computed: {
    ...mapGetters({
      lineOptions: "smrtResource/lineOptions",
      userId: "auth/userId",
      canSelectLine: 'auth/canSelectLine',
      canCreateCashDeclarationForm: 'auth/canCreateCashDeclarationForm',
      canDeleteCashDeclarationForm: 'auth/canDeleteCashDeclarationForm',
    }),
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        get(this.filters.line, "value")
      );
    },
    queryParams() {
      let params = {};
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["searchDateFrom"] = this.parseDateTimeUTC(
          this.filters.dateRange.start,
          true
        );
        params["searchDateTo"] = this.parseDateTimeUTC(
          this.filters.dateRange.end,
          false
        );
      }
      if (this.filters.line) {
        params["lineId"] = this.filters.line.value;
      }
      if (this.filters.station) {
        params["stationId"] = this.filters.station.value;
      }
      return params;
    },
  },
  mounted() {
    this.getPageData();
    this.setDefaultStation();
  },
  methods: {
    get,
    displayUtcDate,
    displayUtcTime,
    formatCurrency,
    resetFilters() {
      this.filters.dateRange = null;
    },
    setDefaultStation() {
      // 默认为用户选择的站点线路
      const defaultSelectedLineId = this.$store.getters["selectedStation/lineId"];
      const defaultSelectedStationId = this.$store.getters["selectedStation/id"];
      // 获取该线路下的站点
      const stationOptions = this.$store.getters["smrtResource/stationOptionsByLineId"](defaultSelectedLineId);

      const defaultSelectedLine = this.lineOptions.find(line => line.value === defaultSelectedLineId);
      const defaultSelectedStation = stationOptions.find(station => station.value === defaultSelectedStationId);

      if (defaultSelectedLine && defaultSelectedStation) {
        this.filters.line = defaultSelectedLine;
        this.filters.station = defaultSelectedStation;
      }
    },
    showButton({ rowData }) {
      return (
        get(rowData, "formStatusEnum") !== this.formStatus.COMPLETED.VALUE &&
        get(rowData, "formStatusEnum") !== this.formStatus.DELETED.VALUE &&
        this.canDeleteCashDeclarationForm
      );
    },
    navigateToView(row) {
      this.$router.push({
        path: '/cash-mgnt/end-discrepancy-form/details',
        query: {id: row.monthEndDeclarationFormId}
      });
    },
    deleteCDF(cdfDetails) {
      this.selectedCDF = cdfDetails;
    },
    async getPageData() {
      this.isSubmitting = true;
      const resp = await getMonthendDeclarAtions({
        ...this.queryParams
      });
      this.isSubmitting = false;
      if (resp.success) {
        this.cdfFormList = get(resp.payload, "list", []);
        this.tableData = this.cdfFormList;
        this.tableData.forEach((item) => {
          if (!item.isTeBagMovedOut && item.formStatusEnum === cdf.cdfStatus.COMPLETED.VALUE)
            item.formStatus = "Pending Move to AFC";
          if (item.isTeBagAtAFC && item.formStatusEnum !== cdf.cdfStatus.DELETED.VALUE)
            item.formStatus = "Moved in to AFC";
        });
        this.isLoading = false;
      } else return false;
    },

    parseDateTimeUTC(datetimeValue, isStartDate) {
      var d = toMoment(datetimeValue);
      if (d.isValid()) {
        var _d = isStartDate ? d.startOf("day") : d.endOf("day");
        const UTC_d = moment.utc(_d);
        return UTC_d.format("YYYY-MM-DD") + "T" + UTC_d.format("HH:mm:ss");
      }
      return "";
    }
  },
};
</script>
